import React from 'react'
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet"
import './map.css'

const Map = () => {
	
	const position = [39.581543, 2.648188];

	//Map container necesita una clase para darle altura a su caja contenedora.
	return (
		
		<MapContainer  className="map" center={position} zoom={18} scrollWheelZoom={false}>

			<TileLayer
			attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
			url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
			/>

			<Marker position={position}>
				<Popup>
					<b>Centro Desarrollo y Lenguaje</b><br/>Sant Joaquim, 8 bjs D<br/>07003 Palma de Mallorca<br/>971 459 777
				</Popup>
			</Marker>
			
	  	</MapContainer>
		
	)
}

export default Map
